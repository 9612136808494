import React, {useState, useContext, useEffect} from "react";
import Dashview from "../Dashview";
import axios from 'axios';
import { v4 } from 'uuid';
import {useHistory, Redirect} from "react-router-dom";
import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';
import {AppContext} from "AppContext";


const OrderSummary = (props) => {

    const { identity, config } = useContext(AppContext);

    console.log("::::: ORDER SUMMARY :::::");
    console.log(props.value);

    let total_cost = 0;
    for(let i=0;i!=props.value.metadata.parts.length; i++) {
        total_cost += props.value.metadata.parts[i].price_total;
    }

    const finishes = [
        { text: "MJF Finish",               value: "MJF_FINISH"},
	{ text: "Vapor Smooth",             value: "VAPOR_SMOOTHING"},
	{ text: "Black Dye",                value: "BLACK_DYE"},
	{ text: "Vapor Smooth / Black Dye", value: "AMT_BLACK_DYE"}
    ];

    return (
        <div className={"rounded-md border width-full mt-4"}>
            <div className={"width-full flex flex-row bg-gray-200 rounded-t pt-4 pl-4 pb-4 pr-4 space-x-8"}>
                <div className={"flex flex-col"}>
                    <div className={"text-sm"}>Order Placed:</div>
                    <div>{props.value.created_at.toDateString()}</div>
                </div>
                <div className={"flex flex-col"}>
                    <div className={"text-sm"}>Total Cost:</div>
                    <div>{total_cost.toFixed(2)}</div>
                </div>
                <div className={"flex flex-grow justify-end"}>
                    <div className={"text-sm"}>Order Id: {props.value.id}</div>
                </div>
            </div>
            <div className={"width-full bg-white p-4"}>
                { props.value.metadata.parts.map((item, index) => {
                    return (
                        <div className={"width-full mb-4 border-b pb-2 border-dashed border-gray-500"} key={index}>
                            <div className={"flex flex-cols space-x-4"}>
                                <div style={{ minWidth: "96px", width: "96px", height: "96px", minHeight: "96px"}}>
                                    <div ><img src={config.storage.host + "/orders/" + identity.userAcctId + "/" + item.snapshot_filename} /></div>
                                </div>
                                <div className={"grid grid-cols-4 gap-4"}>
                                    <div>
                                        <div className={"text-sm"}>
                                            Model filename:
                                        </div>
                                        <div>
                                            {item.original_filename}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"text-sm"}>
                                            Material:
                                        </div>
                                        <div>
                                            {item.material}
                                        </div>
                                    </div>
                                    <div className={"col-span-2"}>
                                        <div className={"text-sm"}>
                                            Finish:
                                        </div>
                                        <div>
                                            { finishes.find((finishType) => finishType.value === item.finish).text }
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"text-sm"}>
                                            Volume:
                                        </div>
                                        <div>
                                            {item.volume.toFixed(2)}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"text-sm"}>
                                            Price:
                                        </div>
                                        <div>
                                            {item.price_per_component.toFixed(2)}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"text-sm"}>
                                            Quantity:
                                        </div>
                                        <div>
                                            {item.quantity}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"text-sm"}>
                                            Total Cost:
                                        </div>
                                        <div>
                                            {item.price_total.toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


const QuoteItem = (props) => {

    // Retrieve configuration information from global context:
    const { identity, config } = useContext(AppContext);
    const [redirect, setRedirect] = useState(false);

    var link = "/dashboard/orders/" + props.value.id;

    let timestamp = props.value.created_at;
    let formatted = '';
    formatted += (timestamp.getMonth() +1 );
    formatted += '/' + (timestamp.getDay().length < 2 ? '0' + timestamp.getDay() : timestamp.getDay());
    formatted += '/' + timestamp.getFullYear();
    formatted += ' - ';
    formatted += (timestamp.getHours().length < 2 ? '0' + timestamp.getHours() : timestamp.getHours());
    formatted += ':';
    formatted += (timestamp.getMinutes().length < 2 ? '0' + timestamp.getMinutes() : timestamp.getMinutes());

    const bgcolor = props.index % 2 === 0 ? "bg-green-50"   : "bg-green-100";
    const hvcolor = props.index % 2 === 0 ? "bg-green-200"  : "bg-green-300";

    // Determine the material name:
    function getMaterialText(m) {
        console.log("looking up: " + m)
        console.log(props.materials)
        let materialText = "unknown";
        for (let x = 0; x != props.materials.length; x++) {
            if (props.materials[x].id === m) {
                materialText = props.materials[x].name;
            }
        }
        return materialText;
    }

    if(redirect === true) {
        return  <Redirect to={{ pathname: link }} />
    }

    return(
        <div className={"flex mt-4 space-x-4 border-b-2 " + bgcolor + " hover:" + hvcolor } onClick={(e) => setRedirect(true)}>
            <div>
                <img src={'/icon-file-stl.png'} width={'64px'} height={'64px'}></img>
            </div>
            <div className={'flex flex-col'}>
                { props.value.metadata.parts.map((item, index) => {
                    return <div key={index}><img src={config.storage.host + "/orders/" + identity.userAcctId + "/" + item.snapshot_filename} width={96} height={96}/></div>
                })}
            </div>
            <div className={'flex flex-col'}>
                { props.value.metadata.parts.map((item, index) => {
                    return (
                        <div key={index}>
                            <span>Material: { getMaterialText(item.material) }</span>
                            <span className={"ml-4"}>Quantity: { item.quantity }</span>
                            <span className={"ml-4"}>Price: ${ item.price}</span>
                            <span className={"ml-4"}>Total Cost: ${ item.quantity * item.price }</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const Content = (props) => {

    const history = useHistory();
    const { identity, config } = useContext(AppContext);
    const [redirect, set_redirect] = useState({ redirect: false, path: ""})
    const [orders, set_orders] = useState([]);
    const [materials, set_materials] = useState([]);

    const [state, dispatch] = useMachine(() => {
        return createMachine({
            id: "component_state_machine",
            initial: "ready",
            states : {
                ready: {
                    on: {
                        REQUEST_CREATE_ORDER : 'redirect_create_order'
                    }
                },
                redirect_create_order: {
                    entry: on_redirect_create_order
                }
            }
        })
    })

    useEffect(()=>{
        // Retrieve all quotes from the server:
        const action = async () => {
            try {
                // Retrieve material data from the server:
                const rxMaterials = await axios.get(config.api.base + '/materials');
                set_materials(rxMaterials.data);
                const response = await axios.get(config.api.base + '/orders/');
                set_orders(response.data);
                console.log("::::: ORDER RESPONSE :::::")
                console.log(response.data);
            } catch(x) {
                console.log(x);
            }

        }
        action();

    }, [])

    function on_redirect_create_order(context, event) {
        set_redirect({ redirect: true, path: "/admin/dashboard/orders/new"})
    }


    function on_click_create_order() {
        dispatch("REQUEST_CREATE_ORDER")
    }

    if(redirect.redirect === true) {
        return <Redirect push to={redirect.path}/>
    }

    return (
        <div className={"container mx-auto"}>
            <div className={"flex w-full border-b-2 border-solid py-4"}>
                <div>
                    <span className={"text-3xl"}>Orders - { identity.userName }</span>
                </div>
                <div className={"flex-grow"}>
                    <div className={"flex h-full justify-end items-center "}>
                        <button className={"green-button h-10"}  onClick={on_click_create_order} style={{ marginRight: '5px'}}>
                            New Order
                        </button>
                    </div>
                </div>
            </div>
            <div>
                { orders.map((item, index) => {
                    return <OrderSummary value={item} materials={materials} key={index} index={index}/>
                })}
            </div>
        </div>
    )

}


const Orders = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Orders;
